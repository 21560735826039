import lazyLoad from "@utils/lazyLoad";

import Root from "@layouts/Root";

const Home = lazyLoad(() => import("@pages/Home"), "default");
const ProInsurance = lazyLoad(() => import("@pages/ProInsurance"), "default");
const SoloInsurance = lazyLoad(() => import("@pages/SoloInsurance"), "default");
// const TechSupport = lazyLoad(() => import("@pages/TechSupport"), "default");

export const publicRoutes = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        // path: '/home',
        element: Home,
      },
      {
        path: "/assurance-professionnelle",
        element: ProInsurance,
      },
      {
        path: "/assurance-particulier",
        element: SoloInsurance,
      },
    ],
  },
  // {
  //   path: "/support",
  //   element: TechSupport,
  // },
];
