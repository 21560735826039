import React from "react";
import { Button, ConfigProvider } from "antd";

import { SolidButtonTypes } from "@/types";

const SolidButton: React.FC<SolidButtonTypes> = (props) => {
  const {
    text,
    colorPrimary,
    textColor,
    icon,
    size,
    onClick,
    styles,
    shape,
    underLine,
    block,
    disabled,
  } = props;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colorPrimary,
          colorTextLightSolid: textColor,
        },
        components: {
          Button: {
            primaryShadow: "0 0px 0 rgba(5, 145, 255, 0.1)",
          },
        },
      }}
    >
      {text ? (
        <Button
        block={block}
          disabled={disabled}
          shape={shape}
          style={styles}
          onClick={onClick}
          type="primary"
          size={size}
          icon={icon}
        >
          <span
            style={
              underLine
                ? { textDecoration: "underline", textUnderlineOffset: "3px" }
                : {}
            }
          >
            {text}
          </span>
        </Button>
      ) : (
        <Button
          disabled={disabled}
          shape={shape}
          style={styles}
          onClick={onClick}
          type="primary"
          size={size}
          icon={icon}
        />
      )}
    </ConfigProvider>
  );
};

export default SolidButton;
