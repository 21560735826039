import { Col, Layout, Row, Space } from "antd";
import asLogoSm from "@assets/asLogo.png";
import { footerImages } from "@/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { version } from "../../package.json";
const { Footer } = Layout;

const RootFooter = () => {
  const date = new Date();

  return (
    <div>
      <Footer className="border-top">
        <Row className="">
          <Col xs={24} sm={24} md={24} lg={8} xl={8} className="px-3">
            <img src={asLogoSm} width={150} />
            <h3 className="footer-subtitle mt-2">
              Plateforme d’échange sécurisée de données
            </h3>
            <p className="footer-text" style={{textAlign:"justify"}}>
              As Solutions se définit comme un courtier grossiste distributeur
              et créateur. Inspiré de l’esprit club et start-up, il mélange
              professionnalisme et expertise dans un esprit convivial.
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={7} xl={7} className="px-3">
            <h3 className="footer-title">Contactez-Nous</h3>
            <div className="my-2">
              <h3 className="footer-subtitle">Adresse :</h3>
              <p className="footer-text">15 Rue Bernoulli, 75008 PARIS, FRANCE</p>
            </div>
            <div className="my-2">
              <h3 className="footer-subtitle">Téléphone :</h3>
              <p className="footer-text">01 84 80 23 89</p>
            </div>
            <div className="my-2">
              <h3 className="footer-subtitle">E-mail :</h3>
              <p className="footer-text">contact@as-solutions.fr</p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} xl={9} className="px-3">
            <h3 className="footer-title">Nos partenaires</h3>
            <div className="row">
              {footerImages.map((image, index) => (
                <div className="col-lg-3 d-flex justify-content-center align-items-center col-6 py-1" key={index}>
                  <img
                    src={image?.src}
                    alt={image?.alt}
                    className="gray-image"
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Footer>
      <Footer
        style={{
          backgroundColor: "#E00000",
          minHeight: 60,
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Space>
              <a
                href="https://www.linkedin.com/company/as-solutions-courtier-grossiste/"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  style={{ color: "white", fontSize: "20px" }}
                  className="mx-2"
                />
              </a>
              <FontAwesomeIcon
                icon={faXTwitter}
                style={{ color: "white", fontSize: "20px" }}
                className="mx-2"
              />
              <FontAwesomeIcon
                icon={faFacebookSquare}
                style={{ color: "white", fontSize: "20px" }}
                className="mx-2"
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <h3
              className="d-flex flex-column flex-lg-row"
              style={{
                color: "white",
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              <div className="my-2" style={{ marginRight: "10px" }}>
                V{version} © {date.getFullYear()} As Solutions{" "}
              </div>
              <ul className="list-unstyled my-2 ">
                <li>
                  <Space>
                    <a
                      href="https://as-solutions.fr/"
                      className="text-white"
                      target="_blank"
                      style={{ fontWeight: 500 }}
                    >
                      À propos de nous{" "}
                    </a>
                    <span>|</span>
                    <a
                      href="https://as-solutions.fr/mention-legales"
                      className="text-white"
                      target="_blank"
                      style={{ fontWeight: 500 }}
                    >
                      {" "}
                      Mentions légales{" "}
                    </a>
                    <span>|</span>
                    <a
                      href="https://as-solutions.fr/politique-de-confidentialite"
                      target="_blank"
                      className="text-white"
                      style={{ fontWeight: 500 }}
                    >
                      {" "}
                      Politique de confidentialité{" "}
                    </a>
                  </Space>
                </li>
              </ul>
            </h3>
          </Col>
        </Row>
      </Footer>
    </div>
  );
};

export default RootFooter;
