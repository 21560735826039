import React, { useState, useMemo, ReactNode } from "react";
import AppContext from "./AppContext";
import {  notification } from "antd";
import axios from "axios";
import { enquireScreen } from "enquire-js";

interface AppContextProviderProps {
  children: ReactNode;
}

// Create the UserContextProvider component
const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  // Define the state
  const navigate = useNavigate();
  const [theme, setTheme] = useState<any>(null);
  const [isMobile, setIsMobile] = useState<boolean | undefined>();
  const [isOpen, setIsOpen] = useState<boolean | undefined>(false);
  const [isHidden, setIsHidden] = useState<boolean | undefined>(true);
  const [assuranceProfessionnel, setAssuranceProfessionnel] = useState<any[]>(
    []
  );
  const [assuranceParticulier, setAssuranceParticulier] = useState<any[]>([]);
  const handleNavigate = (path: string) => {
    navigate(
      path + "?partnership_id=" + localStorage.getItem("partnership_id")
    );
  };

  const partnership_id = new URLSearchParams(window.location.search).get(
    "partnership_id"
  );

  useEffect(() => {
    enquireScreen((b: any) => {
      setIsMobile(!!b);
    });
  }, []);

  useEffect(() => {
    if (partnership_id) {
      let config = {
        headers: {
          Authorization: `Bearer ${partnership_id}`,
        },
      };
      axios
        .post(`${import.meta.env.VITE_API_GEOPROD_URL}login`, {}, config)
        .then((response: { data: { Acces_Token: string } }) => {
          localStorage.setItem("token", response?.data?.Acces_Token);
          localStorage.setItem("partnership_id", partnership_id);
        })
        .catch(() => {
          notification.error({
            message: "Erreur",
            duration: 4,
            description: "Veuillez vous connecter s'il vous plait",
          });

          setTimeout(() => {
            window.location.href = `${
              import.meta.env.VITE_API_DISTRIBUTEUR_APIURL
            }/#/sign-in`;
          }, 1500);
        });
    } else {
      notification.error({
        message: "Erreur",
        duration: 4,
        description: "Veuillez vous connecter s'il vous plait",
      });
      setTimeout(() => {
        window.location.href = `${
          import.meta.env.VITE_API_DISTRIBUTEUR_APIURL
        }/#/sign-in`;
      }, 1500);
    }
  }, [partnership_id]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       let config = {
  //         method: "get",
  //         maxBodyLength: Infinity,
  //         url:
  //           import.meta.env.VITE_API_INTERMEDIAIRE_API_URL +
  //           "catalogue_app/get_lien_tarificateur_v2",
  //         headers: {
  //           "API-KEY": partnership_id,
  //         },
  //       };

  

  //       const tempAssuranceParticulier = data?.assurances_particuliers?.map(
  //         (assurance: any, index: any) => {
  //           const keys = Object.keys(assurance);
  //           const object = assurance[keys[0]];
  //           const products = object?.map((product: any, idx: any) => {
  //             return {
  //               id: `${index}-${idx}`,
  //               title: product?.nom,
  //               info: product?.description,
  //               image: {
  //                 src: product?.logo,
  //                 alt: product?.nom,
  //               },
  //               estimate_link: product?.url_tarification,
  //               waranties_table_link: product?.tableaux_de_garanties,
  //               info_notice_link: product?.notice_information,
  //               age:
  //                 product?.age_min && product?.age_max
  //                   ? `${product?.age_min} - ${product?.age_max} ans`
  //                   : "N/A",
  //               zone: "N/A",
  //               adhesion_link: product?.formulaire_adhesion,
  //               // fiche_produit: product?.support_commercial,
  //             };
  //           });

  //           return {
  //             id: index+1,
  //             title: keys[0],
  //             image: {
  //               src: assurancePro,
  //               alt: "Assurance assurancePro",
  //             },
  //             description: "",
  //             comparateur:
  //               keys[0] === "Complementaire santé"
  //                 ? object[0]?.url_tarification
  //                 : null,
  //             products: products,
  //           };
  //         }
  //       );

  //       const tempAssurancePro = data?.assurances_professionnelles?.map(
  //         (assurance: any, index: any) => {
  //           const keys = Object.keys(assurance);
  //           const object = assurance[keys[0]];
  //           const products = object?.map((product: any, idx: any) => {
  //             return {
  //               id: `${index}-${idx}`,
  //               title: product?.nom,
  //               info: product?.description,
  //               image: {
  //                 src: product?.logo,
  //                 alt: product?.nom,
  //               },
  //               estimate_link: product?.url_tarification,
  //               waranties_table_link: product?.tableaux_de_garanties,
  //               info_notice_link: product?.notice_information,
  //               age:
  //                 product?.age_min && product?.age_max
  //                   ? `${product?.age_min} - ${product?.age_max} ans`
  //                   : "N/A",
  //               zone: "N/A",
  //               adhesion_link: product?.formulaire_adhesion,
  //               // fiche_produit: product?.support_commercial,
  //             };
  //           });

  //           return {
  //             id: index+1,
  //             title: keys[0],
  //             image: {
  //               src: assurancePro,
  //               alt: "Assurance assurancePro",
  //             },
  //             description: "",
  //             comparateur:
  //               keys[0] === "Complementaire santé"
  //                 ? object[0]?.url_tarification
  //                 : null,
  //             products: products,
  //           };
  //         }
  //       );

  //       setAssuranceProfessionnel(tempAssurancePro);
  //       setAssuranceParticulier(tempAssuranceParticulier);
  //     } catch (error) {
  //       message.error("Erreur lors de la récupération des données");
  //     }
  //   }

  //   if (partnership_id) {
  //     fetchData();
  //   }
  // }, [partnership_id]);

  const toggleChat = () => {
    if (isHidden) {
      setIsOpen(true);
      setIsHidden(false);
    } else {
      setIsOpen(true);
      setIsHidden(!isHidden);
    }
  };
  const closeChat = () => {
    setIsOpen(false);
    setIsHidden(true);
  };
  // Memoize the value object to prevent it from changing on every render
  const value = useMemo(
    () => ({
      theme,
      setTheme,
      handleNavigate,
      isMobile,
      toggleChat,
      closeChat,
      isOpen,
      isHidden,
      assuranceProfessionnel,
      assuranceParticulier,
      setAssuranceParticulier,
      setAssuranceProfessionnel,
    }),
    [
      theme,
      setTheme,
      handleNavigate,
      isMobile,
      toggleChat,
      closeChat,
      isOpen,
      isHidden,
      assuranceProfessionnel,
      assuranceParticulier,
      setAssuranceParticulier,
      setAssuranceProfessionnel,
    ]
  );

  // Provide the state to the children components
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
