import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import routes from "./routes";
import AppContextProvider from "@contexts/AppContextProvider";
import UserContextProvider from "@contexts/UserContextProvider";
import { ConfigProvider } from "antd";
import "./index.css";
import "./styles/index.css";
import * as Sentry from "@sentry/react";

const App = () => {
  return useRoutes(routes);
};

if (['production', 'staging'].includes(import.meta.env.VITE_API_DEPLOYMENT_ENVIRONMENT)) {
  Sentry.init({
    dsn: 'https://caa9482e0de1579a443c3c4ce33fec59@sentry.neopolis-dev.com/29',
    tracesSampleRate: 1.0,
    release:import.meta.env.VITE_API_DEPLOYMENT_ENVIRONMENT
  });
}
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Router>
    <AppContextProvider>
      <UserContextProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#E00000",
            },
            components: {
              Carousel: {
                colorBgContainer: "rgb(224, 2, 1)",
              },
            },
          }}
        >
          <App />
        </ConfigProvider>
      </UserContextProvider>
    </AppContextProvider>
  </Router>
);
