import React, { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Flex, FloatButton, Layout } from "antd";
import SolidButton from "@components/SolidButton";
// import Chat from "@components/Chat";

import asLogo from "@assets/asLogo.png";
import RootFooter from "./RootFooter";
import AppContext from "@contexts/AppContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const { Header, Content } = Layout;

const Root: React.FC = () => {
  const { handleNavigate, isMobile } = useContext(AppContext);

  const location = useLocation();

  // const openSupportUrl = () => {
  //   window.open("https://support.neopolis-dev.com/help/2163945848", "_blank");
  // };

  return (
    <Layout className="min-vh-100 overflow-hidden">
      <Header
        className="d-flex justify-content-between bg-white align-items-center border-bottom"
        style={{ height: 130 }}
      >
        <Flex align="center" style={{ width: isMobile ? 130: 0 }}>
          {!isMobile && (
            <div>
              {location?.pathname !== "/" && (
                <SolidButton
                  icon={<FontAwesomeIcon icon={faArrowLeft} />}
                  shape="circle"
                  size="large"
                  textColor="white"
                  colorPrimary="#292371"
                  onClick={() => handleNavigate("/")}
                />
              )}
            </div>
          )}
          {/* <SolidButton
            icon={<i className="fa-regular fa-file-pen"></i>}
            onClick={() => toggleChat()}
            text="Avenant"
            textColor="#282828"
            size="large"
            colorPrimary="#26CB87"
            styles={{ marginLeft: 10 }}
          /> */}
        </Flex>

        <img
          src={asLogo}
          height={!isMobile ? 82.41 : 60}
          style={{ cursor: "pointer" }}
          onClick={() => handleNavigate("/")}
        />

        {/* <SolidButton
          icon={<i className="fa-regular fa-comments-question"></i>}
          onClick={() => openSupportUrl()}
          text="Support"
          textColor="#282828"
          size="large"
          colorPrimary="#26CB87"
        /> */}
        <div></div>
      </Header>
      <Content
        className={`bg-white py-3 ${isMobile ? "" : "content-bg"}`}
        style={{ minHeight: "80vh" }}
      >
        <div className={`px-4 py-2 ${isMobile ? "" : "text-center"} `}>
          <h1
            className={`${
              isMobile
                ? "display-6 fw-bold inter-ff"
                : "display-5 fw-bold text-white inter-ff"
            }`}
            style={{ fontSize: isMobile ? 24 : 28 }}
          >
            Solutions d'Assurance - Choix et Flexibilité
          </h1>
          <div className="col-lg-6 mx-auto">
            <h1
              className={`${
                isMobile
                  ? "display-6 fw-bold inter-ff"
                  : "display-5 fw-bold text-white inter-ff"
              }`}
              style={{ fontSize: isMobile ? 16 : 18 }}
            >
              Un éventail de gammes pour satisfaire les besoins de vos clients
            </h1>
          </div>
        </div>
        <Outlet />
        {/* <Chat /> */}
        <FloatButton.BackTop type="primary" />
      </Content>
      <RootFooter />
    </Layout>
  );
};

export default Root;
